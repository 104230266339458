/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, type LinkProps } from 'bb/app/nav/Link';
import { useTranslationsFromDiscovery } from '../hooks/useTranslationsFromDiscovery';
import { Badge, type BadgeProps } from './Badge';

export type BadgeLinkProps = Omit<LinkProps, 'ref'> & BadgeProps;

export const BadgeLink = (props: BadgeLinkProps) => {
    const { translationKey, icon, ...linkProps } = props;
    const text = useTranslationsFromDiscovery(translationKey);
    const { t } = useTranslation(['badge']);
    const allyText = `${t(`book:accessibility_badge`)} ${t(`badge:accessibility_${icon}`, '')} ${text}`;

    return (
        <Link
            {...linkProps}
            style={{ textDecoration: 'none' }}
            aria-label={allyText}
        >
            <Badge translationKey={translationKey} icon={icon} />
        </Link>
    );
};
