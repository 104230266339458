/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Text } from 'bb/i18n';
import { Flex, Row } from 'bb/ui/Flex';
import { useTranslationsFromDiscovery } from '../hooks/useTranslationsFromDiscovery';
import { ImageIcon, type SvgName } from '../Icons';
import css from './badge.module.scss';

export type BadgeProps = {
    translationKey: string;
    icon?: SvgName;
};

export const Badge = (props: BadgeProps) => {
    const { translationKey, icon } = props;
    const text = useTranslationsFromDiscovery(translationKey);

    return (
        <Row alignItems="center" className={css.base}>
            {icon && (
                <Flex
                    justifyContent="center"
                    alignItems="center"
                    className={css.icon}
                >
                    <ImageIcon svg={icon} size="small" />
                </Flex>
            )}
            <Text as="span" variant="body3" className={css.text} aria-hidden>
                {text}
            </Text>
        </Row>
    );
};
