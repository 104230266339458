import { useEffect } from 'react';
import { useRouter } from 'bb/app/router';
import { isValidMarket } from 'bb/config/marketConfig';
import { Cookies, SIX_MONTHS } from 'bb/cookies/config';
import { getCookieValue, setCookieValue } from 'bb/cookies/utils';

export type UseSetMarketCookieOptions = {
    /**
     * If true, we try setting the market in cookies.
     *
     * @defaultValue `false`
     */
    trySetCookie?: boolean;
};

/*
 * If the market cookie differs from the market the user is visiting,
 * for example when changing market and getting a cached page (not getting bb_market cookie refreshed on the server),
 * then we try setting the new market in cookies, if it does not work we reload the page with refreshmarket=true,
 * which will bypass cache and hit our servers.
 */
export const useSetMarketCookie = (options: UseSetMarketCookieOptions = {}) => {
    const { trySetCookie = false } = options;
    const { router } = useRouter();
    const { query, pathname } = router;
    const { market } = query;

    useEffect(() => {
        /*
         * Return early if no valid market in path, for example on /market
         */
        if (typeof market === 'string' && isValidMarket(market)) {
            let marketCookie = getCookieValue(Cookies.MARKET_COOKIE);

            /*
             * Get the market cookie and compare it to the market in path.
             * If they differ, set the market in cookies.
             */
            if (marketCookie !== market) {
                // eslint-disable-next-line max-depth
                if (trySetCookie) {
                    setCookieValue({
                        name: Cookies.MARKET_COOKIE,
                        value: market,
                        maxAge: SIX_MONTHS
                    });
                }
            }
            /*
             * Get the market cookie again and compare it to the market in path.
             * If they still differ, reload the page with refreshmarket=true.
             */
            marketCookie = getCookieValue(Cookies.MARKET_COOKIE);

            if (marketCookie !== market) {
                router.replace({
                    pathname,
                    query: { ...router.query, refreshmarket: true }
                });
            }
        }
    }, [market, trySetCookie, pathname, router]);

    return null;
};
