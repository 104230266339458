export { match, is } from './match';
export { shortSwitch } from './shortSwitch';
export * from './conversion';
export { inIframe } from './inIframe';
export { debounce } from './debounce';
export {
    isBrowser,
    isServer,
    canUseLocalStorage,
    isDevEnvironment
} from './environment';
export * from './date';
export { noop } from './noop';
export { getAction } from './signup';
export { getFromQuery, getImageUrl, setSearchParams } from './urlUtils';
export { parseData, stringify, downloadJson } from './json';
export { getTimeAndMinutes, getTimeLeft } from './time';
export { cleanQueryParams } from './cleanQueryParams';
export { replaceKeys } from './replaceKeys';
export {
    getCampaignCodeFromQuery,
    campaignQueryCodes
} from './getCampaignCodeFromQuery';
export { useFormatDate } from './hooks/useFormatDate';
export { clamp } from './clamp';
export { deleteNullish } from './deleteNullish';
export * from './assert';
export { decodeBase64 } from './decodeBase64';
export { uniqueByKey } from './unique';
export { allEqualType } from './allEqualType';
export * from './renderStrayComponent';
export * from './toast';
export { unRef } from './unRef';
export * from './isWithinRange';
export * from './assignRef';
export * from './makeSpacing';
export * from './sample';
export * from './sleep';
export * from './getKey';
export * from './translationKey';

export { getNextFocusableElement } from './getNextFocusableElement';
