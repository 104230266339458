import { type IncomingMessage } from 'http';
import { getPublicEnv } from 'bb/config/env';

export const isDevEnvironment = () => process.env.NODE_ENV === 'development';

export const isBrowser = (): boolean =>
    typeof window !== 'undefined' && typeof document !== 'undefined';

export const isServer = (): boolean => typeof window === 'undefined';

export const canUseShare = (): boolean =>
    typeof navigator !== 'undefined' && typeof navigator.share !== 'undefined';

export const getEnvironment = () =>
    getPublicEnv('NEXT_PUBLIC_BB_ENV') as string;

export const isProductionEnvironment = (): boolean =>
    getEnvironment() === 'production';

export const canUseLocalStorage = (): boolean =>
    isBrowser() && Boolean(window.localStorage);

export const canUseSessionStorage = (): boolean =>
    isBrowser() && Boolean(window.sessionStorage);

/**
 * If the request url starts with '/_next/data/' it is not an initial page load, but
 * a subsequent re-route on the client side.
 */
export const isInitialServerSideRequest = (req: IncomingMessage): boolean =>
    req.url?.startsWith('/_next/data/') === false;
