import { useEffect, useMemo } from 'react';
import { useRouter } from 'bb/app/router';
import {
    REGISTRATION_SOURCE_QUERY_NAME,
    REACTIVATE_ACCOUNT_QUERY_NAME
} from 'bb/app/router/consts';

/**
 * In order to be able to persist query params properly
 * we need to do run the add function at a very early
 * stage. This is because navigate() runs A LOT in the
 * app, and we wanna make sure that we have persisted
 * what we want before the first navigate() gets called.
 *
 * This should be called at _app level or similar.
 */
export const usePersistentAppQueryParams = () => {
    const { persistQueryParams, routes } = useRouter();

    /**
     * The href is the pathname for the page that we
     * perist a given key on. This hook is not
     * responsible for deleting the persisted key.
     */
    const toPersist = useMemo(
        () => [
            {
                href: routes.selectSubscription.href,
                key: REGISTRATION_SOURCE_QUERY_NAME
            },
            {
                href: routes.reactivate2.href,
                key: REACTIVATE_ACCOUNT_QUERY_NAME
            }
        ],
        [routes.selectSubscription.href, routes.reactivate2.href]
    );

    useEffect(() => {
        toPersist.forEach(({ href, key }) => {
            persistQueryParams.add(href, key);
        });
    }, [toPersist, persistQueryParams]);

    return null;
};
