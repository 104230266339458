import { useVerifyLoggedIn } from 'bb/account/hooks/useVerifyLoggedIn';
import { useRouter } from 'bb/app/router/useRouter';
import { useFeature } from 'bb/config/features';
import {
    type RoutesAdditionalData,
    type InternalRoutes,
    type Routes
} from './Breadcrumbs.types';

export const TRANSLATION_NAMESPACE_KEY = 'breadcrumbs' as const;

export const useBreadcrumbs = () => {
    const { routes: routerRoutes } = useRouter();
    const { data: { isLoggedIn } = {} } = useVerifyLoggedIn();
    const newAccountPage = useFeature('my-account-nav-drawer');

    const accountRoot = newAccountPage ? 'myaccount' : 'welcome';
    const rootParent = isLoggedIn === true ? accountRoot : 'start';

    /**
     * Additional data used by the breadcrumbs. We need to keep track of
     * the each route's parent to be able to construct the breadcrumbs.
     */
    const routesWithParent = {
        /**
         * Static pages
         */
        cookies: {
            parent: rootParent
        },
        about: {
            parent: rootParent
        },
        contact: {
            parent: rootParent
        },
        school: {
            parent: 'contact'
        },
        sitemap: {
            parent: rootParent
        },
        cancel: {
            parent: rootParent
        },
        privacyNotice: {
            parent: rootParent
        },
        terms: {
            parent: rootParent
        },
        giftcardTerms: {
            parent: rootParent
        },
        activateaccount: {
            parent: rootParent
        },
        forgottenPassword: {
            parent: rootParent
        },
        student: {
            parent: rootParent
        },
        welcome: {
            parent: null
        },
        reactivate: {
            parent: rootParent
        },
        reactivate2: {
            parent: rootParent
        },
        campaignTerms: {
            parent: rootParent
        },
        loginAndCompletePayment: {
            parent: rootParent
        },
        summaryPrivacyPolicy: {
            parent: rootParent
        },
        userlab: {
            parent: rootParent
        },
        topUpSuccess: {
            parent: rootParent
        },
        awaitAccountActivation: {
            parent: rootParent
        },
        trialRefused: {
            parent: rootParent
        },
        partners: {
            parent: rootParent
        },
        contest: {
            parent: rootParent
        },
        environmentalImpact: {
            parent: rootParent
        },
        notFound: {
            parent: rootParent
        },
        selectMarket: {
            parent: rootParent
        },
        magicLinkCannotBeOpened: {
            parent: rootParent
        },
        subcontractors: {
            parent: rootParent
        },
        familyaccount: {
            parent: rootParent
        },
        /**
         * Dynamic pages
         */
        start: {
            parent: null
        },
        userDetails: {
            parent: rootParent
        },
        selectSubscription: {
            parent: rootParent
        },
        signupSession: {
            parent: rootParent
        },
        accountActivated: {
            parent: rootParent
        },
        login: {
            parent: rootParent
        },
        promocode: {
            parent: rootParent
        },
        recruitLanding: {
            parent: rootParent
        },
        buyGiftcards: {
            parent: rootParent
        },
        redeemGiftcard: {
            parent: 'buyGiftcards'
        },
        selectGiftcardPeriod: {
            parent: 'buyGiftcards'
        },
        giftcardPayment: {
            parent: 'buyGiftcards'
        },
        giftcardAwaiting: {
            parent: 'buyGiftcards'
        },
        giftcardComplete: {
            parent: 'buyGiftcards'
        },
        cancelSubscription: {
            parent: rootParent
        },
        overview: {
            parent: null
        },
        paymenthistory: {
            parent: accountRoot
        },
        settings: {
            parent: accountRoot
        },
        receipt: {
            parent: accountRoot
        },
        recruitAFriend: {
            parent: accountRoot
        },
        devices: {
            parent: accountRoot
        },
        accountDetails: {
            parent: accountRoot
        },
        campaigns: {
            parent: accountRoot
        },
        press: {
            parent: rootParent
        },
        profiles: {
            parent: accountRoot
        },
        giftcards: {
            parent: accountRoot
        },
        myhours: {
            parent: accountRoot
        },
        subscription: {
            parent: accountRoot
        },
        myhistory: {
            parent: accountRoot
        },
        inbox: {
            parent: accountRoot
        },
        recruitafriend: {
            parent: accountRoot
        },
        myaccount: {
            parent: null
        },
        books: {
            parent: rootParent
        },
        categories: {
            parent: rootParent
        },
        category: {
            parent: 'categories'
        },
        search: {
            parent: rootParent
        },
        book: {
            parent: 'books'
        },
        bookBeta: {
            parent: 'books'
        },
        bookList: {
            parent: 'books'
        },
        series: {
            parent: rootParent
        },
        authorPage: {
            parent: 'search'
        },
        narratorPage: {
            parent: 'search'
        },
        author: {
            parent: 'search'
        },
        narrator: {
            parent: 'search'
        },
        partner: {
            parent: rootParent
        },
        verifyPartner: {
            parent: 'partner'
        },
        partnerSubscription: {
            parent: 'partner'
        },
        partnerWelcome: {
            parent: 'partner'
        },
        bookCheckout: {
            parent: 'book'
        },
        bookAwaiting: {
            parent: 'book'
        },
        bookComplete: {
            parent: 'book'
        },
        badge: {
            parent: 'books'
        },
        landingPage: {
            parent: rootParent
        },
        strawberry: {
            parent: rootParent
        },
        'e-books': {
            parent: 'books'
        },
        registrationV2: {
            parent: rootParent
        }
    } as const satisfies Record<keyof Routes, RoutesAdditionalData>;

    /**
     * Merge the routes from the router with the additional data and a
     * transKey function to be able to translate the route names.
     */
    const routes = (
        Object.entries(routesWithParent) as [
            keyof Routes,
            (typeof routesWithParent)[keyof typeof routesWithParent]
        ][]
    ).reduce<InternalRoutes>((acc, [key, route]) => {
        acc[key] = {
            ...route,
            ...routerRoutes[key],
            key,
            transKey: (k) => `${TRANSLATION_NAMESPACE_KEY}:${k}`
        };

        return acc;
    }, {} as InternalRoutes);

    /**
     * Returns the breadcrumbs for a given route. We start with the
     * leaf route and traverse the parent chain until we reach the top.
     */
    const getBreadcrumbs = (route: keyof Routes) => {
        const breadCrumbs = [route];
        let { parent } = routes[route] ?? {};

        while (parent) {
            breadCrumbs.unshift(parent);
            parent = routes[parent].parent;
        }

        return breadCrumbs.length === 1
            ? []
            : breadCrumbs.map((crumb) => routes[crumb]);
    };

    return {
        routes,
        getBreadcrumbs
    };
};

export const isDynamicSegment = (str: string) =>
    str.startsWith('[') && str.endsWith(']');
