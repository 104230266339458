import React, { useMemo } from 'react';
import { type Route } from 'bb/app/router/config';
import {
    RemappedRoutesProvider,
    type RemappedRoutesProviderProps
} from 'bb/app/router/RemappedRoutesContext';
import { useHasFeature } from 'bb/config/features';

export type AppRemappedRoutesProviderProps = Pick<
    RemappedRoutesProviderProps,
    'children'
>;

export const AppRemappedRoutesProvider = ({
    children
}: AppRemappedRoutesProviderProps) => {
    const hasFeature = useHasFeature();

    const value: RemappedRoutesProviderProps['value'] = useMemo(() => {
        const newRegistrationRoute = 'registrationV2' as Route;
        const registrationRoutes = hasFeature('registration-v2')
            ? {
                  userDetails: newRegistrationRoute,
                  selectSubscription: newRegistrationRoute,
                  signupSession: newRegistrationRoute,
                  accountActivated: newRegistrationRoute,
                  awaitAccountActivation: newRegistrationRoute,
                  trialRefused: newRegistrationRoute
              }
            : {};
        const newAccountRoute = 'myaccount' as Route;
        const accountRoutes = hasFeature('my-account-nav-drawer')
            ? {
                  settings: newAccountRoute,
                  subscription: newAccountRoute,
                  overview: newAccountRoute
              }
            : {};
        return { ...registrationRoutes, ...accountRoutes };
    }, [hasFeature]);

    return (
        <RemappedRoutesProvider value={value}>
            {children}
        </RemappedRoutesProvider>
    );
};
