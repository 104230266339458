import { useApp } from 'bb/app/AppContext';

export const useFormatDate = (date?: string | Date) => {
    const { locale } = useApp();

    if (date === undefined) {
        return null;
    }
    if (!date) {
        return 'N/A';
    }
    if (date instanceof Date) {
        return date.toLocaleDateString(locale);
    }

    return new Date(date).toLocaleDateString(locale);
};
